<div class="rentals-dialog-container">
  <div class="dialog-header">
    <h2 style="line-height: 1.5rem; font-family: Montserrat;">{{ data.propertyName }}</h2>
    <button mat-icon-button class="close-button" (click)="onClose()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  
  <div class="dialog-content">
    <div class="property-info">
      <p class="property-count">{{ data.rents.length }} Available Rentals &#x2022; {{ data.periodDisplay }}</p>
    </div>
    
    <div class="rentals-grid">
      @for (rent of data.rents; track rent.getId()) {
        <app-rent-card [rent]="rent" [openHere]="true"></app-rent-card>
      }
    </div>
  </div>

  <div class="dialog-footer">
    <button mat-flat-button style="font-family: Montserrat; background-color: var(--mon-gray);" (click)="onClose()">Close</button>
  </div>
</div>
