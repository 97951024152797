<div class="property-sidebar-container">
  <div class="search-box">
    <input type="text" placeholder="Search Properties..." [(ngModel)]="searchTerm" (input)="onSearch()">
  </div>
  
  <div class="group-tabs">
    <button 
      class="tab-button" 
      [class.active]="activeGroupType === 'address'"
      (click)="switchGroupType('address')">
      Address
    </button>
    <button 
      class="tab-button" 
      [class.active]="activeGroupType === 'roomType'"
      (click)="switchGroupType('roomType')">
      Type
    </button>
    <button 
      class="tab-button" 
      [class.active]="activeGroupType === 'listingType'"
      (click)="switchGroupType('listingType')">
      Listing
    </button>

    <button 
      class="tab-button" 
      [class.active]="activeGroupType === 'roomId'"
      (click)="switchGroupType('roomId')">
      ID
    </button>
  </div>
  
  <div class="property-list">
    @if(filteredRentals.length === 0) {
      <div class="no-properties-sidebar">
        <div class="icon-container">
          <span class="material-icons">search_off</span>
        </div>
        <p>No properties available</p>
      </div>
    } @else {
      <!-- All Properties Section -->
      <div class="property-section" 
           [class.active]="selectedProperty === 'All Properties'"
           (click)="selectProperty('All Properties')">
        <span class="property-name" style="color: var(--mon-gray);">All Properties</span>
        <span class="property-count" style="color: var(--mon-gray);">{{filteredRentals.length}} units</span>
      </div>
      
      <!-- Dynamic Groups based on active group type -->
      @for (group of activeGroups; track group.name) {
        <div class="property-section" 
             [class.active]="selectedProperty === group.name"
             (click)="selectProperty(group.name)">
          <span class="property-name">{{group.name}}</span>
          <span class="property-count">{{group.count}}</span>
        </div>
      }
    }
  </div>
</div>
