import { ChangeDetectionStrategy, Component, Input, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import Feature, { FeatureLike } from 'ol/Feature';
import Map from 'ol/Map';
import View from 'ol/View';
import { Point, Geometry } from 'ol/geom';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import { fromLonLat } from 'ol/proj';
import OSM from 'ol/source/OSM';
import VectorSource from 'ol/source/Vector';
import { Circle as CircleStyle, Fill, Stroke, Style, Text } from 'ol/style';
import Select from 'ol/interaction/Select';
import { click } from 'ol/events/condition';
import { RentCardComponent } from "../../modules/home/find-a-home/all-listings/rent-card/rent-card.component";
import Overlay from 'ol/Overlay';
import { Rent } from '@core/classes/rent';

@Component({
  selector: 'app-properties-map',
  standalone: true,
  imports: [RentCardComponent],
  templateUrl: './properties-map.component.html',
  styleUrls: ['./properties-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PropertiesMapComponent implements OnChanges { 

  
  map!: Map;
  @Input({required: true})
  rents !: Rent[];

  @Input()
  address :string = "";

  @Input()
  feature?: any;

  display: Rent | null = null;
  zoom = 10;
  
  // Umbral para considerar marcadores como cercanos (en metros)
  private readonly PROXIMITY_THRESHOLD = 20;
  
  // Propiedades para manejar grupos de marcadores cercanos
  private markerGroups: Record<string, Rent[]> = {};
  private activeGroupIndices: Record<string, number> = {};
  private markersFeatures: Record<string, Feature<Geometry>> = {};
  private markerOverlays: Record<string, Overlay> = {};
  private vectorSource: VectorSource | null = null;

  constructor(private changes : ChangeDetectorRef){}

  /**
   * Cierra el popup de la propiedad seleccionada
   */
  closeDisplay() {
    this.display = null;
    this.changes.detectChanges();
  }
  
  /**
   * Formatea un precio para mostrar en el mapa
   */
  formatPrice(price: number): string {
    // Mostrar el precio completo sin abreviaturas
    return price.toString();
  }
  
  /**
   * Calcula la distancia en metros entre dos puntos geográficos
   */
  private calculateDistance(lon1: number, lat1: number, lon2: number, lat2: number): number {
    const R = 6371e3; // Radio de la Tierra en metros
    const φ1 = lat1 * Math.PI / 180;
    const φ2 = lat2 * Math.PI / 180;
    const Δφ = (lat2 - lat1) * Math.PI / 180;
    const Δλ = (lon2 - lon1) * Math.PI / 180;

    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
              Math.cos(φ1) * Math.cos(φ2) *
              Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c; // Distancia en metros
  }
  
  /**
   * Identifica y agrupa marcadores que están cerca entre sí
   */
  private groupNearbyMarkers(properties: Rent[]): Record<string, Rent[]> {
    const groups: Record<string, Rent[]> = {};
    const processed = new Set<string>();
    
    for (let i = 0; i < properties.length; i++) {
      const property = properties[i];
      const propertyId = property.getRentRoom().id.toString();
      
      if (processed.has(propertyId)) continue;
      
      const group: Rent[] = [property];
      processed.add(propertyId);
      
      // Buscar propiedades cercanas a esta
      for (let j = 0; j < properties.length; j++) {
        const otherProperty = properties[j];
        const otherPropertyId = otherProperty.getRentRoom().id.toString();
        
        if (i === j || processed.has(otherPropertyId)) continue;
        
        const distance = this.calculateDistance(
          property.getRentRoom().location.longitud, 
          property.getRentRoom().location.latitud,
          otherProperty.getRentRoom().location.longitud, 
          otherProperty.getRentRoom().location.latitud
        );
        
        // Si está cerca, añadir al grupo
        if (distance < this.PROXIMITY_THRESHOLD) {
          group.push(otherProperty);
          processed.add(otherPropertyId);
        }
      }
      
      // Guardar el grupo si tiene al menos un elemento
      if (group.length > 0) {
        groups[propertyId] = group;
        // Inicializar el índice activo para este grupo
        this.activeGroupIndices[propertyId] = 0;
      }
    }
    
    return groups;
  }

  /**
   * Crea un marcador para una propiedad
   */
  private createMarker(property: Rent, isGroup: boolean = false, groupIndex: number = 0, totalInGroup: number = 1): Feature<Geometry> {
    const marker = new Feature({
      geometry: new Point(fromLonLat([property.getRentRoom().location.longitud, property.getRentRoom().location.latitud])),
      properties: property,
      isGroup: isGroup,
      groupId: property.getRentRoom().id.toString(),
      groupIndex: groupIndex,
      totalInGroup: totalInGroup
    });
    
    // Texto del precio formateado para mostrar en el marcador
    let priceText = `${property.getRentRoom().price.currency.symbol}${this.formatPrice(property.getRentRoom().price.money)}`;
    
    // Estilo normal
    const defaultStyle = new Style({
      image: new CircleStyle({
        radius: 0, // Hacemos invisible el círculo, solo queremos el texto con fondo
        fill: new Fill({
          color: 'rgba(0,0,0,0)'
        }),
        stroke: new Stroke({
          color: 'rgba(0,0,0,0)',
          width: 0
        })
      }),
      text: new Text({
        text: priceText,
        font: 'bold 12px Montserrat',
        fill: new Fill({
          color: '#FFFFFF'
        }),
        backgroundFill: new Fill({
          color: 'rgba(65, 82, 115, 0.9)'
        }),
        backgroundStroke: new Stroke({
          color: '#FFFFFF',
          width: 2
        }),
        padding: [5, 8, 5, 8],
        offsetY: 0,
        textAlign: 'center',
        scale: 1.2
      })
    });
    
    // Estilo al pasar el ratón
    const hoverStyle = new Style({
      image: new CircleStyle({
        radius: 0, // Hacemos invisible el círculo
        fill: new Fill({
          color: 'rgba(0,0,0,0)'
        }),
        stroke: new Stroke({
          color: 'rgba(0,0,0,0)',
          width: 0
        })
      }),
      text: new Text({
        text: priceText,
        font: 'bold 12px Montserrat',
        fill: new Fill({
          color: '#FFFFFF'
        }),
        backgroundFill: new Fill({
          color: 'rgba(65, 82, 115, 1)'
        }),
        backgroundStroke: new Stroke({
          color: '#FFFFFF',
          width: 2.5
        }),
        padding: [6, 10, 6, 10],
        offsetY: 0,
        textAlign: 'center',
        scale: 1.3
      })
    });
    
    marker.setStyle(defaultStyle);
    marker.set('defaultStyle', defaultStyle);
    marker.set('hoverStyle', hoverStyle);
    
    // Si este marcador es parte de un grupo, guardarlo para referencia futura
    if (isGroup) {
      this.markersFeatures[`${property.getRentRoom().id.toString()}_${groupIndex}`] = marker;
    }
    
    return marker;
  }

  /**
   * Crea un overlay HTML para un marcador que forma parte de un grupo
   */
  private createMarkerOverlay(property: Rent, groupId: string, groupIndex: number, totalInGroup: number): Overlay {
    // Crear un elemento div para el overlay
    const element = document.createElement('div');
    element.className = 'marker-carousel';
    
    // Contenido HTML del marcador con flechas de navegación a los lados
    element.innerHTML = `
      <div class="carousel-navigation">
        <div class="arrow arrow-left" data-direction="-1" data-group="${groupId}">◀</div>
        <div class="price-container">
          <span class="price-tag">${property.getRentRoom().price.currency.symbol}${this.formatPrice(property.getRentRoom().price.money)}</span>
          ${totalInGroup > 1 ? `<span class="position-badge">${groupIndex + 1}/${totalInGroup}</span>` : ''}
        </div>
        <div class="arrow arrow-right" data-direction="1" data-group="${groupId}">▶</div>
      </div>
    `;
    
    // Configurar eventos para las flechas
    const leftArrow = element.querySelector('.arrow-left');
    const rightArrow = element.querySelector('.arrow-right');
    
    if (leftArrow) {
      leftArrow.addEventListener('click', (e) => {
        e.stopPropagation();
        this.navigateMarkerGroup(groupId, -1);
      });
    }
    
    if (rightArrow) {
      rightArrow.addEventListener('click', (e) => {
        e.stopPropagation();
        this.navigateMarkerGroup(groupId, 1);
      });
    }
    
    // Añadir evento de clic al contenedor de precio para mostrar detalles
    const priceContainer = element.querySelector('.price-container');
    if (priceContainer) {
      priceContainer.addEventListener('click', () => {
        this.display = property;
        this.changes.detectChanges();
      });
    }
    
    // Crear y devolver el overlay
    const overlay = new Overlay({
      element: element,
      positioning: 'center-center',
      stopEvent: false
    });
    
    // Guardar referencia al overlay
    const overlayKey = `${groupId}_${groupIndex}`;
    this.markerOverlays[overlayKey] = overlay;
    
    return overlay;
  }

  /**
   * Navega al siguiente o anterior marcador en un grupo
   * @param groupId ID del grupo de marcadores
   * @param direction 1 para avanzar, -1 para retroceder
   */
  navigateMarkerGroup(groupId: string, direction: number): void {
    if (!this.map || !this.vectorSource || !this.markerGroups[groupId]) return;
    
    const group = this.markerGroups[groupId];
    const totalInGroup = group.length;
    
    // Eliminar overlay actual
    const currentIndex = this.activeGroupIndices[groupId];
    const currentOverlayKey = `${groupId}_${currentIndex}`;
    if (this.markerOverlays[currentOverlayKey]) {
      this.map.removeOverlay(this.markerOverlays[currentOverlayKey]);
    }
    
    // Calcular el nuevo índice
    let newIndex = (currentIndex + direction) % totalInGroup;
    if (newIndex < 0) newIndex = totalInGroup - 1;
    
    // Actualizar el índice activo
    this.activeGroupIndices[groupId] = newIndex;
    
    // Crear y añadir el nuevo marcador y overlay
    const property = group[newIndex];
    const marker = this.createMarker(property, true, newIndex, totalInGroup);
    const overlay = this.createMarkerOverlay(property, groupId, newIndex, totalInGroup);
    
    // Establecer posición del overlay
    const coordinates = (marker.getGeometry() as Point).getCoordinates();
    overlay.setPosition(coordinates);
    
    // Actualizar la fuente del vector y añadir el overlay
    if (this.vectorSource && this.markersFeatures[currentOverlayKey]) {
      this.vectorSource.removeFeature(this.markersFeatures[currentOverlayKey]);
    }
    this.vectorSource.addFeature(marker);
    this.map.addOverlay(overlay);
    
    // Actualizar el display con la propiedad actual
    this.display = property;
    
    // Actualizar el mapa
    this.changes.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges){
    if(this.map){
      this.map.dispose();
    }
    
    if (!this.rents || this.rents.length === 0) {
      console.warn("No hay propiedades para mostrar en el mapa");
      return;
    }
    
    // Limpiar referencias anteriores
    this.markerOverlays = {};
    this.markersFeatures = {};
    
    // Agrupar marcadores cercanos
    this.markerGroups = this.groupNearbyMarkers(this.rents);
    
    // Crear marcadores para el mapa
    let markers: Feature<Geometry>[] = [];
    
    // Inicializar el mapa
    this.map = new Map({
      view: new View({  
        center: fromLonLat([this.rents[0].getRentRoom().location.longitud, this.rents[0].getRentRoom().location.latitud]),
        zoom: this.zoom,
      }),
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      target: 'ol-map'
    });
    
    // Para cada grupo, mostrar solo el primer marcador inicialmente
    Object.entries(this.markerGroups).forEach(([groupId, group]) => {
      const property = group[0];
      
      // Si es un grupo de más de uno, crear marcador con indicadores de navegación
      if (group.length > 1) {
        const marker = this.createMarker(property, true, 0, group.length);
        markers.push(marker);
        
        // Crear un overlay HTML para este grupo
        const overlay = this.createMarkerOverlay(property, groupId, 0, group.length);
        const coordinates = (marker.getGeometry() as Point).getCoordinates();
        overlay.setPosition(coordinates);
        this.map.addOverlay(overlay);
      } else {
        // Si es solo uno, crear marcador normal pero con el mismo estilo que los grupos
        const marker = this.createMarker(property);
        markers.push(marker);
        
        // Crear un overlay HTML para este marcador individual
        const overlay = this.createMarkerOverlay(property, property.getRentRoom().id.toString(), 0, 1);
        const coordinates = (marker.getGeometry() as Point).getCoordinates();
        overlay.setPosition(coordinates);
        this.map.addOverlay(overlay);
      }
    });
    
    // Crear la fuente de vectores y la capa
    this.vectorSource = new VectorSource({
      features: markers
    });
    
    const vectorLayer = new VectorLayer({
      source: this.vectorSource
    });

    const select = new Select({
      condition: click,
      layers: [vectorLayer],
      style: function(feature: FeatureLike) {
        return feature.get('hoverStyle');
      }
    });
    
    select.on('select', (e: any) => {
      if (e.selected.length > 0) {
        const selectedFeature = e.selected[0];
        
        // Si no se hizo clic en las flechas, mostrar la propiedad
        const properties = selectedFeature.get('properties');
        this.display = properties;
        this.changes.detectChanges();
        
        // Restaurar la selección después de un breve retraso
        setTimeout(() => {
          select.getFeatures().clear();
        }, 1000);
      }
    });
    
    // Efecto hover para los marcadores
    const featureOverlay = new VectorLayer({
      source: new VectorSource(),
      map: this.map,
      style: function(feature: FeatureLike) {
        return feature.get('hoverStyle');
      }
    });
    
    let highlight: Feature<Geometry> | null = null;
    const highlightFeature = (feature: Feature<Geometry> | null) => {
      if (feature !== highlight) {
        if (highlight) {
          const source = featureOverlay.getSource();
          if (source) {
            source.removeFeature(highlight);
          }
        }
        if (feature) {
          const source = featureOverlay.getSource();
          if (source) {
            source.addFeature(feature);
          }
        }
        highlight = feature;
      }
    };
    
    // Manejar movimiento del ratón sobre el mapa
    this.map.on('pointermove', (e: any) => {
      if (e.dragging) {
        highlightFeature(null);
        return;
      }
      
      const feature = this.map.forEachFeatureAtPixel(e.pixel, 
        (feature: any, layer: any) => {
          return feature as FeatureLike;
        }, {
          layerFilter: (layer: any) => {
            return layer === vectorLayer;
          }
        });
      
      this.map.getTargetElement().style.cursor = feature ? 'pointer' : '';
      highlightFeature(feature as Feature<Geometry> | null);
    });
    
    this.map.addLayer(vectorLayer);
    this.map.addInteraction(select);
    this.changes.detectChanges();
  }
}
