<div class="calendar-view-container">
  <div class="calendar-header">
    <div class="view-controls">
      <button mat-icon-button matTooltip="Previous" (click)="previousPeriod()" [disabled]="page === 0">
        <mat-icon>chevron_left</mat-icon>
      </button>
      <button mat-button (click)="goToToday()" class="today-button">Today</button>
      <button mat-icon-button matTooltip="Next" (click)="nextPeriod()">
        <mat-icon>chevron_right</mat-icon>
      </button>
      <span class="date-display" style="font-family: Montserrat; color: var(--mon-gray);">{{ currentDate().format('MMMM YYYY') }}</span>
    </div>
    
    <div class="view-type-toggle">
      <button mat-button style="font-family: Montserrat; color: var(--mon-gray);" [class.active]="viewType() === 'week'" (click)="switchViewType('week')">Day</button>
      <button mat-button style="font-family: Montserrat; color: var(--mon-gray);" [class.active]="viewType() === 'month'" (click)="switchViewType('month')">Week</button>
      <button mat-button style="font-family: Montserrat; color: var(--mon-gray);" [class.active]="viewType() === 'year'" (click)="switchViewType('year')">Month</button>
    </div>
  </div>


  <div class="calendar-body">
    @if (calendarData().length) {
      <table class="calendar-table">
        <thead>
          <tr>
            <th class="property-header">{{ propertyHeaderLabel() }}</th>
            @for (header of calendarHeaders(); track $index) {
              <th class="date-header" [class.past-date]="">
                <div class="main-header">{{ header.main }}</div>
                @if (header.sub) {
                  <div class="sub-header">{{ header.sub }}</div>
                }
              </th>
            }
          </tr>
        </thead>
        <tbody>
          @for (propertyData of calendarData(); track $index) {
            <tr class="property-row">
              <td class="property-cell">
                <div class="property-name">{{ propertyData.property }}</div>
                <div class="property-units">{{ propertyData.units }} units total</div>
              </td>
              
              @for (period of calendarPeriods(); track $index) {
                <td (click)="clickRentals($event,propertyData, period)" class="availability-cell {{calculateColor(propertyData, period)}} {{propertyData.dates[period.real]  ? 'has-units' : 'no-hast'}}">
                  <div class="availability-number">{{ propertyData.dates[period.real] }}</div>
                  <div class="availability-label">
                    @if(propertyData.dates[period.real]){
                      units available
                    }@else if(calculateColor(propertyData, period) === 'no-availability') {
                      Not units
                    }
                  </div>
                </td>
              }
            </tr>
          }
        </tbody>
      </table>
    }
  </div>
</div>
