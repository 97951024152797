import { AsyncPipe, CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { AllListingsComponent } from "@modules/home/find-a-home/all-listings/all-listings.component";
import { IFolder, IRent } from '@core/interfaces/rent.interface';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RentManager } from '@core/managers/RentManager';
import { SEOService } from '@services/seo.service';
import { FileDropboxComponent } from '@components/file-dropbox/file-dropbox.component';
import { Utils } from '@utils/Utils';
import moment, { Moment } from 'moment';
import { SnackbarService } from '@services/snackbar.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { map, Observable, of, startWith } from 'rxjs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ROUTES_NAME } from '@data/const/routes.data';
import { GroupType, PropertySidebarComponent } from './property-sidebar/property-sidebar.component';
import { CalendarViewComponent } from './calendar-view/calendar-view.component';
import { RouterModule } from '@angular/router';
import { PropertiesMapComponent } from "../../../components/properties-map/properties-map.component";
import { CalendarComponent } from "../../../components/calendar/calendar.component";
import { FiltersFindHomeComponent, IfiltersOutter } from '@modules/home/find-a-home/filters-find-home/filters-find-home.component';
import { Rent } from '@core/classes/rent';

export interface IamountRental {'total': number, 'published': number, 'all':number, 'hidden':number}


@Component({
  selector: 'app-listings',
  standalone: true,
  imports: [
    CommonModule,
    AllListingsComponent,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatTooltipModule,
    FileDropboxComponent,
    MatCheckboxModule,
    PropertySidebarComponent,
    CalendarViewComponent,
    RouterModule,
    PropertiesMapComponent,
    FiltersFindHomeComponent,
    CalendarComponent
],
  templateUrl: './listings.component.html',
  styleUrls: ['./listings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListingsComponent {


  tab : string = 'Published';
  viewType: string = 'list'; 
  rentals : Rent[] = [];  // Array original (todas las propiedades)
  rentals_to_show : Rent[] = []; // Array filtrado para mostrar
  rentals_filtered_by_search : Rent[] = []; // Resultado de filtros por fecha y filtros especiales
  activeGroupType: GroupType = 'address';
  selectedProperty: string = 'All Properties';

  ROUTES_NAME = ROUTES_NAME;
  protected isLoading = false;
  start : FormControl = new FormControl(null);
  end : FormControl = new FormControl(null);
  private _notFound : boolean = false;
  onFilters : boolean = false;
  public get notFound () : boolean { return this._notFound;}

  toggleFilters(): void {
    this.onFilters = !this.onFilters;
    this.changes.detectChanges();
    
    // Si estamos abriendo el filtro, ajustar su posición
    if (this.onFilters) {
      setTimeout(() => {
        this.adjustFilterMenuPosition();
      }, 0);
    }
  }

  onFilterClose(event: boolean): void {
    this.onFilters = !event;
    this.changes.detectChanges();
  }

  // Ajusta la posición del menú de filtros si se sale de la pantalla
  private adjustFilterMenuPosition(): void {
    const filterMenu = document.querySelector('.menu-filters') as HTMLElement;
    if (!filterMenu) return;

    // Obtener dimensiones y posición
    const menuRect = filterMenu.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    
    // Verificar si el menú se sale por la derecha
    if (menuRect.right > viewportWidth - 20) {
      // Calcular cuánto se sale
      const overflowRight = menuRect.right - (viewportWidth - 20);
      // Mover a la izquierda lo necesario para evitar el overflow
      const currentLeft = parseInt(getComputedStyle(filterMenu).left) || 0;
      filterMenu.style.left = (-50+ currentLeft - overflowRight) + 'px';
    }
  }

  // Reajustar cuando cambie el tamaño de la ventana
  @HostListener('window:resize')
  onResize(): void {
    if (this.onFilters) {
      this.adjustFilterMenuPosition();
    }
  }

  private _amount : IamountRental = {
    total: 0,
    all: 0,
    published: 0,
    hidden: 0
  };
  public get amountRent () : IamountRental {return this._amount;}

  @ViewChild('showMore') showMore!: ElementRef;
  constructor(
    private rentManager : RentManager,
    private seo : SEOService,
    private snackbar : SnackbarService,
    private changes : ChangeDetectorRef){
    this.detectMode();
    this.seo.setTitle('Dashboard - Listings')
  }

  isAdmin(){
    return this.rentManager.getAuth().hasPermission(false);
  }

  filtersToApply ?: IfiltersOutter;
  applyFilters(filters : IfiltersOutter){
    this.filtersToApply = filters;
    this.search();
  }

  search() {
    const arrive : Moment|null = this.start.value;
    const departure : Moment|null = this.end.value;
    const filters = this.filtersToApply;
  
    // Filtrar por fecha y filtros especiales (siempre desde rentals original)
    let filtered = this.rentals.filter((rental : Rent) => {
      if(!arrive || !departure) return true;
      const available : Moment = rental.getDateAvailable();
      return available.isBetween(arrive, departure, undefined, rental.inclusivity_calendar);
    });

    if(filters) {
      const { beds, rooms, min, max, amenities, facilities } = filters;
      
      if(beds != null && beds !== undefined) {
        const bd = Utils.toNumber(beds);
        if(bd !== null) {
          filtered = filtered.filter((rental : Rent) => rental.getRentRoom().listing?.information?.beds.total === bd);
        }
      }
      if(rooms != null && rooms !== undefined) {
        const rm = Utils.toNumber(rooms);
        if(rm !== null) {
          filtered = filtered.filter((rental : Rent) => rental.getRentRoom().listing?.information?.bedrooms === rm);
        }
      }
      if(min != null && min !== undefined) {
        const mn = Utils.toNumber(min);
        if(mn !== null) {
          filtered = filtered.filter((rental : Rent) => rental.getRentRoom().price.money >= mn);
        }
      }
      if(max != null && max !== undefined) {
        const mx = Utils.toNumber(max);
        if(mx !== null) {
          filtered = filtered.filter((rental : Rent) => rental.getRentRoom().price.money <= mx);
        }
      }
      if(amenities && amenities.length) filtered = filtered.filter((rental : Rent) => this.checkKeys(rental.getRentRoom().listing?.amenities, amenities));
      if(facilities && facilities.length) filtered = filtered.filter((rental : Rent) => this.checkKeys(rental.getRentRoom().listing?.facilities, facilities));
    }
    // Guardar el resultado de los filtros de fecha y especiales
    this.rentals_filtered_by_search = filtered;

    // Aplicar el filtro de propiedad si hay uno seleccionado
    this.applyPropertyFilter();

    // Detectar si no hay resultados y mostrar mensaje
    this._notFound = this.rentals_to_show.length === 0;

    this.changes.detectChanges();
  }
  
  checkKeys(obj: any|undefined, keys: string[]): boolean {
    if(!obj) return false;
    return keys.every(key => obj[key] === true);
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    if (this.isLoading || !this.showMore || this._notFound) return;

    const element = this.showMore.nativeElement;
    const elementPosition = element.offsetTop;
    const scrollPosition = window.scrollY + window.innerHeight;

    if (scrollPosition > elementPosition) {
      if(!this.rentals_to_show.length){
        this.viewMore();
      }
      
    }
  }

  getRentalsSize(){
    this.rentManager.size({
      next: (result)=>{
        this._amount = result;
        this.changes.detectChanges();
      }
    })

  }

  filterByProperty(property: string): void {
    this.selectedProperty = property;
    
    // Actualizar el rentals_to_show basado en la propiedad seleccionada y los filtros de búsqueda
    this.applyPropertyFilter();
    
    this.changes.markForCheck();
  }

  // Método auxiliar para aplicar el filtro de propiedad
  private applyPropertyFilter(): void {
    // El filtrado de propiedades solo afecta a all-listings (rentals_to_show)
    // La base para filtrar es el resultado de los filtros por fecha y filtros especiales
    const hasActiveFilters = this.filtersToApply || (this.start.value && this.end.value);
    
    // Si no hay filtros de búsqueda, usamos rentals original
    const baseToFilter = hasActiveFilters ? this.rentals_filtered_by_search : this.rentals;
    
    if (this.selectedProperty === 'All Properties') {
      // Si es "All Properties", simplemente copiamos la base
      this.rentals_to_show = [...baseToFilter];
    } else {
      // Sino, filtramos por la propiedad seleccionada
      this.rentals_to_show = baseToFilter.filter(rental => {
        return (
          rental.getRentRoom().location?.address === this.selectedProperty ||
          rental.getRentRoom().listing?.roomType === this.selectedProperty ||
          rental.getRentRoom().listing?.listingType === this.selectedProperty ||
          rental.getRentRoom().roomId === this.selectedProperty
        );
      });
    }

    // Actualizar el estado de notFound si después del filtro no hay propiedades
    this._notFound = this.rentals_to_show.length === 0;
    
    // Forzar detección de cambios para garantizar actualización de la vista
    this.changes.detectChanges();
  }

  deleteListing(param : {id : number,index:number}) {
    this.rentals.splice(param.index, 1);
  }

  toggleHideListing(param : {id : number,index:number}) {
    const hidden = this.rentals[param.index].getRentRoom().hidden;
    if(hidden){
      this._amount.published--;
      this._amount.hidden++;
      if(this.tab == 'Published'){
        this.rentals.splice(param.index, 1);
      }
    }else{
      this._amount.published++;
      this._amount.hidden--;
      if(this.tab == 'Hidden'){
        this.rentals.splice(param.index, 1);
      }
    }



  }

  detectMode (){
    switch(this.tab){
      case 'All':
        this.rentals = [];
        this.getRentals();
        return;
      case 'Published':
        this.rentals = [];
        this.getRentals(false);
        return;
      case 'Hidden':
        this.rentals = [];
        this.getRentals(true);
        return;
    }
  }

  viewMore(){
    switch(this.tab){
      case 'All':
        this.getRentals();
        return;
      case 'Published':
        this.getRentals(false);
        return;
      case 'Hidden':
        this.getRentals(true);
        return;
    }
  }

  toggle(tab : string) {
    this.tab = tab;
    this.detectMode();
  }

  switchViewType(type: string) {
    this.viewType = type;
    this.changes.detectChanges();
  }

  loadingBookinSheet : boolean = false;
  bookingSheet(event : FileList){
    const file : File | null = event.item(0);
    if(!file) return;

    const reader = new FileReader();

    reader.onload = (event) => {
        const result = event.target?.result;
        if(typeof result == 'string'){
          const csv : string[] =  result.split('\r\n');
          const headers : string[] = csv.splice(0,1)[0].split(';');
          
          const booking : any = [];
          csv.forEach((r : string)=>{
            const row : string[] = r.split(';');

            const row_ : any = {};
            row.forEach((val : any,index : number)=>{
              const h = headers[index];
              if(['LISTING ID', 'Available from','Room Price','Gender'].includes(h)){
                if(h == 'Room Price'){
                  val = val.replace('€','');
                  val = val.replace(',','');
                  val = Number.parseFloat(val);
                }else if(h == 'Available from'){
                  
                  const start = Utils.strToMoment(val,'DD/MM/YYYY');
                  const end = moment()
                  if (start <= end) {
                    val = [];  
                  }else{
                    val = start.format('YYYY-MM-DD');
                    const nowdate : string = end.format('YYYY-MM-DD');

                    val = [{
                      start : nowdate,
                      end : val
                    }]
                  }
                  
                }
                row_[h] = val;
              }
            })

            booking.push(row_);
          })
          
          this.loadingBookinSheet = true;
          this.rentManager.bookingSheet(booking,{
            next : (response)=>{
              if(response == 'OK')
                this.snackbar.text('Booking sheet updated','ok');
              else
                this.snackbar.text(`The next room id couldn't be updated: [${response.join(', ')}]`,'warn',false);
            },
            finalize : () => {
              this.loadingBookinSheet = false;
              this.changes.detectChanges();
            }
          })

        }
    };

    reader.onerror = () => {};

    reader.readAsText(file, 'UTF-8');
  }

  getRentals(hidden ?: boolean) {
    const length : number = this.rentals.length;
    const fromId : number|undefined = length ? this.rentals[length - 1].getRentRoom().id : undefined;
    this.isLoading = true;
    this._notFound = false;

    this.getRentalsSize();
    this.rentManager.get({many:'all',preview:true,fromId:fromId,hidden:hidden,order:'old'},{
      next : (result)=>{
        if(length){
          this.rentals.push(...result.map((rent : IRent) => new Rent(rent)));
        }else{
          this.rentals = result.map((rent : IRent) => new Rent(rent));
        }
      },
      error : (error)=>{
        this._notFound = true;
      },
      finalize : () => {
        this.isLoading = false;
        this.changes.detectChanges();
      }
    },true)

  }

  ngOnInit(){
    

  }
}
