import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { RentCardComponent } from '@modules/home/find-a-home/all-listings/rent-card/rent-card.component';
import { Rent } from '@core/classes/rent';

export interface RentalsDialogData {
  rents: Rent[];
  propertyName: string;
  periodDisplay: string;
}

@Component({
  selector: 'app-rentals-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    RentCardComponent
  ],
  templateUrl: './rentals-dialog.component.html',
  styleUrls: ['./rentals-dialog.component.scss']
})
export class RentalsDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<RentalsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RentalsDialogData
  ) { }

  onClose(): void {
    this.dialogRef.close();
  }
}
