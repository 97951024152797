import { Component, Input, Signal, computed, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import moment from 'moment';
import { Rent } from '@core/classes/rent';
import { GroupType } from '../property-sidebar/property-sidebar.component';
import { RentalsDialogComponent } from './rentals-dialog';

// Types for our calendar
export type CalendarViewType = 'week' | 'month' | 'year';
export type DateRangeType = 'standard' | 'twoWeeks' | 'fiveWeeks';
export type PropertyAvailability = {
  property: string;
  propertyType: string;
  rents : { [date: string]: Rent[] };
  units: number;
  dates: { [date: string]: number }; // date -> available units
  colorClasses : any[];
};

@Component({
  selector: 'app-calendar-view',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonToggleModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatDialogModule,
    FormsModule
  ],
  templateUrl: './calendar-view.component.html',
  styleUrls: ['./calendar-view.component.scss']
})
export class CalendarViewComponent{

  @Input() set rentals(value: Rent[]) {
    this._rentals.set(value);
  }

  @Input() set groupType(value: GroupType) {
    this._groupType.set(value);
  }

  @Input() selectedProperty: string | null = null;

  // Reactive signals
  private _rentals = signal<Rent[]>([]);
  private _currentDate = signal<moment.Moment>(moment());
  private _viewType = signal<CalendarViewType>('month');
  private _groupType = signal<GroupType>('address');
  private _selectedRange = signal<DateRangeType>('standard');
  
  // Computed values
  currentDate: Signal<moment.Moment> = this._currentDate.asReadonly();
  viewType: Signal<CalendarViewType> = this._viewType.asReadonly();
  groupTypeSignal: Signal<GroupType> = this._groupType.asReadonly();
  selectedRange: Signal<DateRangeType> = this._selectedRange.asReadonly();
  
  calendarData = signal<PropertyAvailability[]>([]);
  calendarPeriods = signal<{real: string, display: string , date : moment.Moment}[]>([]);
  
  cache_colorclasess : any = {
    "week" : {},
    "month": {},
    "year" : {}
  };

  // Derived data for headers
  calendarHeaders = computed(() => {
    const periods = this.calendarPeriods();
    const viewType = this._viewType();
    
    if (viewType === 'week') {
      return periods.map(period => {
        // For week view, period is already in "ddd D" format (e.g. "Mon 15")
        return {
          main: period.display,
          sub: ''
        };
      });
    } else if (viewType === 'month') {
      return periods.map(period => {
        const [month, weekPart] = period.display.split(' Week ');
        return {
          main: month,
          sub: weekPart ? `Week ${weekPart}` : ''
        };
      });
    } else {
      return periods.map(period => {
        return {
          main: period.display,
          sub: ''
        };
      });
    }
  });

  // Property header label based on groupType
  propertyHeaderLabel = computed(() => {
    switch (this._groupType()) {
      case 'address':
        return 'Property Address';
      case 'roomType':
        return 'Room Type';
      case 'listingType':
        return 'Listing Type';
      case 'roomId':
        return 'Room ID';
      default:
        return 'Property';
    }
  });



  ngOnChanges(){
    this.goToToday();
  }

  /**
   * Changes the view type (week, month, year)
   */
  switchViewType(type: CalendarViewType): void {
    this._viewType.set(type);
    this.goToToday();
  }

  /**
   * Navigates to the previous period
   */
  previousPeriod(): void {
    this.page--;
    const currentDate = this._currentDate().clone();
    const viewType = this._viewType();
    
    if (viewType === 'week') {
      currentDate.subtract(1, 'weeks');
    } else if (viewType === 'month') {
      currentDate.subtract(1, 'months');
    } else {
      currentDate.subtract(1, 'years');
    }
    
    this._currentDate.set(currentDate);
    this.updateCalendarData();
  }

  loadedPage : any = {};
  page : number = 0;
  /**
   * Navigates to the next period
   */
  nextPeriod(): void {
    this.page++;
    const currentDate = this._currentDate().clone();
    const viewType = this._viewType();
    
    if (viewType === 'week') {
      currentDate.add(1, 'weeks');
    } else if (viewType === 'month') {
      currentDate.add(1, 'months');
    } else {
      currentDate.add(1, 'years');
    }
    
    this._currentDate.set(currentDate);
    this.updateCalendarData();
  }

  /**
   * Returns to the current date
   */
  goToToday(): void {
    this.page = 0;
    this.loadedPage = {};
    this.cache_colorclasess = {
      week: {},
      month: {},
      year: {}
    };
    this._currentDate.set(moment());
    this.updateCalendarData();
  }

  /**
   * Update calendar data based on current date and view type
   */
  private updateCalendarData(): void {
    const viewType = this._viewType();
    const currentDate = this._currentDate().clone();
    const rentals = this._rentals();
    const groupType = this._groupType();
    
    let periodLabels: {real: string, display: string, date: moment.Moment}[] = [];
    const availabilityData: PropertyAvailability[] = [];
    
    // Calculate period labels and prepare data
    if (viewType === 'week') {
      periodLabels = this.getWeekPeriods(currentDate);
    } else if (viewType === 'month') {
      periodLabels = this.getMonthPeriods(currentDate);
    } else {
      periodLabels = this.getYearPeriods(currentDate);
    }
    
    // Process rentals
    if (rentals.length > 0) {
      // Group rentals based on groupType
      const propertyGroups: { [key: string]: Rent[] } = {};
      
      rentals.forEach(rental => {
        let groupKey = 'Unknown';
        
        switch (groupType) {
          case 'address':
            groupKey = rental.getRentRoom().location?.address || 'Unknown Address';
            break;
          case 'roomType':
            groupKey = rental.getRentRoom().listing?.roomType || 'Unknown Room Type';
            break;
          case 'listingType':
            groupKey = rental.getRentRoom().listing?.listingType || 'Unknown Listing Type';
            break;
          case 'roomId':
            groupKey = rental.getRentRoom().roomId || 'Unknown Room ID';
            break;
        }
        
        if (!propertyGroups[groupKey]) {
          propertyGroups[groupKey] = [];
        }
        propertyGroups[groupKey].push(rental);
      });
      
      // Create availability data for each property group
      Object.keys(propertyGroups).forEach(groupKey => {
        const rentalsInGroup = propertyGroups[groupKey];
        
        const availability: PropertyAvailability = {
          property: groupKey,
          propertyType: groupType,
          units: rentalsInGroup.length,
          rents : {},
          dates: {},
          colorClasses : []
        };
        
        rentalsInGroup.forEach(rental => {
          const rn = rental.getDateAvailable();

          let period = rn.format('MMM') + " Week "+ Math.ceil(rn.date()/ 7) + " " + rn.format('YYYY');
          availability.dates[period] = (availability.dates[period] ?? 0) + 1;
          if( availability.rents[period]){
            availability.rents[period].push(rental);
          }else{
            availability.rents[period] = [rental];
          }

          period = rn.format('ddd D MMM YYYY');
          if (!availability.dates[period]){
            availability.colorClasses.push(rn);
          }
          availability.dates[period] = (availability.dates[period] ?? 0) + 1;
          if( availability.rents[period]){
            availability.rents[period].push(rental);
          }else{
            availability.rents[period] = [rental];
          }

          period = rn.format('MMM YYYY');
          availability.dates[period] = (availability.dates[period] ?? 0) + 1;
          if( availability.rents[period]){
            availability.rents[period].push(rental);
          }else{
            availability.rents[period] = [rental];
          }
          
         
        });

        if(!(this.loadedPage[this.page] ?? false)){
          if(!this.cache_colorclasess[this._viewType()][availability.property]){
            this.cache_colorclasess[this._viewType()][availability.property] = {
              color : "no-availability",
              period_before : null
            };
          }
          
          const vals : any = Object.values(this.cache_colorclasess[this._viewType()][availability.property]);
          const latest  = vals[vals.length - 1] ?? null;
          let colorbefore : string = latest?.color ?? "no-availability";
          let periodbefore : string = latest?.period_before ?? null;

          for(const period of periodLabels){
            if(availability.dates[period.real]) {
              colorbefore = this.getActualColor(availability.dates[period.real]);
              periodbefore = period.real;

              this.cache_colorclasess[this._viewType()][availability.property][period.real] = {
                color : colorbefore,
                period_before : periodbefore
              };
              
              continue;
            }
            
            this.cache_colorclasess[this._viewType()][availability.property][period.real] = {
              color : colorbefore,
              period_before : periodbefore
            };
          }
        }

        availabilityData.push(availability);
      });
    }
    
    this.loadedPage[this.page] = true;
    this.calendarPeriods.set(periodLabels);
    this.calendarData.set(availabilityData);
  }

  public clickRentals(event: MouseEvent, propertyData: any, period: any) {
    event.preventDefault();
    event.stopPropagation();
    
    const rents : Rent[]|undefined = propertyData.rents[period.real] ?? undefined;
    if(!rents || !rents.length) return;

    // Show the rentals in the dialog
    this.openRentalsDialog(rents, propertyData.property, period.display);
  }
  
  constructor(private dialog: MatDialog) {}

  /**
   * Opens a dialog displaying rentals for the selected period and property
   */
  private openRentalsDialog(rents: Rent[], propertyName: string, periodDisplay: string): void {
    const dialogRef = this.dialog.open(RentalsDialogComponent, {
      maxWidth: '1200px',
      width: 'fit-content',
      data: { 
        rents: rents, 
        propertyName: propertyName,
        periodDisplay: periodDisplay
      },
      panelClass: 'rentals-dialog'
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Handle any actions when the dialog is closed with a result
      }
    });
  }
  
  /**
   * Gets week periods for the calendar
   */
  private getWeekPeriods(baseDate: moment.Moment): {real: string, display: string, date: moment.Moment}[] {
    const periods: {real: string, display: string, date: moment.Moment}[] = [];
    // Start from the beginning of the week containing the base date
    const startDate = baseDate.clone().startOf('week');
    
    // Generate 7 days for the week view (Sunday to Saturday)
    for (let i = 0; i < 7; i++) {
      const dayDate = startDate.clone().add(i, 'days');
      // Format as "Day Num" (e.g., "Mon 15")
      periods.push({real : dayDate.format('ddd D MMM YYYY'), display: dayDate.format('ddd D'), date: dayDate});
    }
    
    return periods;
  }

  /**
   * Gets month periods for the calendar
   */
  private getMonthPeriods(baseDate: moment.Moment): {real: string, display: string, date: moment.Moment}[] {
    const periods: {real: string, display: string, date: moment.Moment}[] = [];
    const startDate = baseDate.clone().startOf('month');
    const endDate = baseDate.clone().endOf('month');
    const monthName = this.getMonthName(startDate.month());
    
    // Find first day of first week (start from beginning of month)
    const firstDay = startDate.clone().startOf('week');
    
    // Generate weeks for the month
    let weekNum = 1;
    while (firstDay.isSameOrBefore(endDate)) {
      // Only add weeks that have at least one day in the current month
      if (firstDay.clone().add(6, 'days').month() === startDate.month() || 
          firstDay.month() === startDate.month()) {
        periods.push({real : `${monthName} Week ${weekNum} ${firstDay.format('YYYY')}`, display: `${monthName} Week ${weekNum}`, date: firstDay});
      }
      firstDay.add(7, 'days');
      weekNum++;
    }
    
    // Ensure we have at least one period
    if (periods.length === 0) {
      periods.push({real : `${monthName} Week 1 ${startDate.format('YYYY')}`, display: `${monthName} Week 1`, date: startDate});
    }
    
    return periods;
  }

  /**
   * Gets year periods for the calendar
   */
  private getYearPeriods(baseDate: moment.Moment): {real: string, display: string, date: moment.Moment}[] {
    const year = baseDate.year();
    const periods: {real: string, display: string, date: moment.Moment}[] = [];
    
    // Generate all 12 months for the year
    for (let i = 0; i < 12; i++) {
      periods.push({real : this.getMonthName(i) + ' ' + year, display: this.getMonthName(i), date: moment().month(i).year(year)});
    }
    
    return periods;
  }

  /**
   * Helper function to get month name
   */
  private getMonthName(month: number): string {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    return months[month];
  }
  
  

  calculateColor(property  : PropertyAvailability, period: { real: string; display: string; date: moment.Moment; }) {
    const obj = this.cache_colorclasess[this._viewType()][property.property][period.real];
    if(!obj) return 'no-availability';
    return obj.color;
  }

  getActualColor(units : number|null|undefined){
    if (!units) {
      return 'no-availability';
    }
    if ( units < 3) {
      return 'low-availability';
    }
    if (units < 6) {
      return 'medium-availability';
    }
    return 'high-availability';
  }


}
