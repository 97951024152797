import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Rent } from '@core/classes/rent';

interface PropertyGroup {
  name: string;
  count: number;
  properties: Rent[];
}

export type GroupType = 'address' | 'roomType' | 'listingType' | 'roomId';

@Component({
  selector: 'app-property-sidebar',
  standalone: true, 
  imports: [CommonModule, FormsModule],
  templateUrl: './property-sidebar.component.html',
  styleUrls: ['./property-sidebar.component.scss']
})
export class PropertySidebarComponent implements OnInit, OnChanges {
  @Input() rentals: Rent[] = [];
  @Input() set groupType(value: GroupType) {
    this.activeGroupType = value;
  }
  @Output() propertySelected = new EventEmitter<string>();
  @Output() groupTypeChanged = new EventEmitter<GroupType>();
  
  searchTerm: string = '';
  filteredRentals: Rent[] = [];
  selectedProperty: string = 'All Properties';
  roomIdGroups: PropertyGroup[] = [];
  addressGroups: PropertyGroup[] = [];
  roomTypeGroups: PropertyGroup[] = [];
  listingTypeGroups: PropertyGroup[] = [];
  private _activeGroupType: GroupType = 'address';
  
  get activeGroupType(): GroupType {
    return this._activeGroupType;
  }
  
  set activeGroupType(type: GroupType) {
    if (this._activeGroupType !== type) {
      this._activeGroupType = type;
      this.groupTypeChanged.emit(type);
    }
  }
  
  get activeGroups(): PropertyGroup[] {
    switch (this.activeGroupType) {
      case 'address':
        return this.addressGroups;
      case 'roomType':
        return this.roomTypeGroups;
      case 'listingType':
        return this.listingTypeGroups;
      case 'roomId':
        return this.roomIdGroups;
      default:
        return this.addressGroups;
    }
  }
  
  ngOnInit() {
    this.filteredRentals = [...this.rentals];
    this.generateGroups();
  }
  
  ngOnChanges(changes: SimpleChanges) {
    if (changes['rentals']) {
      this.filteredRentals = [...this.rentals];
      this.generateGroups();
    }
  }
  
  onSearch() {
    if (!this.searchTerm.trim()) {
      this.filteredRentals = [...this.rentals];
    } else {
      const term = this.searchTerm.toLowerCase().trim();
      this.filteredRentals = this.rentals.filter(rental => {
        // Search by address
        if (rental.getRentRoom().location?.address?.toLowerCase().includes(term)) {
          return true;
        }
        
        // Search by room type
        if (rental.getRentRoom().listing?.roomType?.toLowerCase().includes(term)) {
          return true;
        }
        
        // Search by listing type
        if (rental.getRentRoom().listing?.listingType?.toLowerCase().includes(term)) {
          return true;
        }
        
        // Search by room ID
        if (rental.getRentRoom().roomId?.toLowerCase().includes(term)) {
          return true;
        }
        
        return false;
      });
    }
    
    this.generateGroups();
  }
  
  switchGroupType(type: GroupType): void {
    this.activeGroupType = type;
  }
  
  selectProperty(property: string) {
    this.selectedProperty = property;
    this.propertySelected.emit(property);
  }
  
  private generateGroups() {
    this.addressGroups = this.groupByProperty('location.address');
    this.roomTypeGroups = this.groupByProperty('listing.roomType');
    this.listingTypeGroups = this.groupByProperty('listing.listingType');
    this.roomIdGroups = this.groupByProperty('roomId');
  }
  
  private groupByProperty(propertyPath: string): PropertyGroup[] {
    const groups: Record<string, Rent[]> = {};
    
    this.filteredRentals.forEach(rental => {
      // Handle nested properties using path notation
      const paths = propertyPath.split('.');
      let value: any = rental.getRentRoom();
      
      for (const path of paths) {
        if (value && value[path] !== undefined) {
          value = value[path];
        } else {
          value = undefined;
          break;
        }
      }
      
      const key = value?.toString() || 'undefined';
      
      if (key === 'undefined') {
        return;
      }
      
      if (!groups[key]) {
        groups[key] = [];
      }
      
      groups[key].push(rental);
    });
    
    return Object.entries(groups)
      .map(([name, properties]) => ({
        name,
        count: properties.length,
        properties
      }))
      .sort((a, b) => b.count - a.count);
  }
}
