<section class="main-section">
    <div class="section-container">
        <div class="listings-header">
            <header class="header-content">
                <h1 class="page-title">Property Catalog</h1>
                @if(isAdmin()){
                    <p class="subtitle">Manage and monitor all your property listings in one place</p>
                }@else {
                    <p class="subtitle">Manage and monitor all the catalog in one place</p>
                }
            </header>
        </div>
        <div class="filter-tabs">
            <button [disabled]="isLoading" type="button" title="All" [ngClass]="{'active':tab=='All'}" class="tab-button" (click)="toggle('All')">
                <span>All Properties</span>
                <span class="count-badge">{{amountRent.all}}</span>
            </button>
            <button [disabled]="isLoading" type="button" title="Published" [ngClass]="{'active':tab=='Published'}" class="tab-button" (click)="toggle('Published')">
                <span>Published</span>
                <span class="count-badge">{{amountRent.published}}</span>
            </button>
            <button [disabled]="isLoading" type="button" title="Hidden" [ngClass]="{'active':tab=='Hidden'}" class="tab-button" (click)="toggle('Hidden')">
                <span>Hidden</span>
                <span class="count-badge">{{amountRent.hidden}}</span>
            </button>
        </div>

        
        <mat-divider></mat-divider>
        
        

        @if(isAdmin()){
            <div class="tools-section">
                <div class="tools-section-left">
                    <article matTooltip="Add new listing" class="add-listing-article" style="background-color: white; border-radius: 0.8rem; box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08); transition: all 0.3s ease; overflow: hidden; height: auto !important; display: inline-block;">
                        <a routerLink="{{ROUTES_NAME.properties.parent}}/listings/new" style="display: flex; align-items: center; padding: 0.8rem 1.2rem; text-decoration: none;">
                            <mat-icon style="color: var(--mon-red); font-size: 1.5rem; margin-right: 0.5rem;">add_circle</mat-icon>
                            <span style="font-size: 0.9rem; font-weight: 600; color: #415273; font-family: Montserrat;">Add New Listing</span>
                        </a>
                    </article>
                    <div class="import-tool">
                        <app-file-dropbox [icon]="null" [ngClass]="{'loading-booking' : loadingBookinSheet}" [description]="'Import Booking Sheet'" [titleOn]="false" (files)="bookingSheet($event)" class="booking-sheet-uploader" [filesType]="['.csv,text/csv']"></app-file-dropbox>
                    </div>
                </div>

                <div class="view-types">
                    <div class="view-type-buttons">
                        <button [disabled]="isLoading" [ngClass]="{'active': viewType === 'list'}" class="view-type-button" (click)="switchViewType('list')" matTooltip="List View">
                            <mat-icon>view_list</mat-icon>
                        </button>
                        <button [disabled]="isLoading" [ngClass]="{'active': viewType === 'calendar'}" class="view-type-button" (click)="switchViewType('calendar')" matTooltip="Calendar View">
                            <mat-icon>calendar_month</mat-icon>
                        </button>
                        <button [disabled]="isLoading" [ngClass]="{'active': viewType === 'map'}" class="view-type-button" (click)="switchViewType('map')" matTooltip="Property Map View">
                            <mat-icon>map</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        }

        <section [ngStyle]="{'width': '90%', 'max-width': '1204px'}" class="calendar-filter-section" style="margin-bottom: 1em; display: flex; flex-wrap: wrap; gap: 1em; justify-content: center;">
            <app-calendar [closeOnClear]="true" (on_close)="search()" [navigate]="false" [closeOnSelected]="true" [flexible]="true" [startDate]="start" [endDate]="end"></app-calendar>
    
            <div style="display: flex;align-items: center; gap: 1em; height: 55px; position: relative; ">
            
            <button aria-label="Filters button" type="button" (click)="toggleFilters()" [class.active]="onFilters" class="filter-button" style="width: 60px; cursor: pointer; height: 100%; border-radius: 4px; outline: none; border: none; box-shadow: 3px 5px 7px -1px rgba(0,0,0,0.5);">
                <mat-icon>
                tune
                </mat-icon>
            </button>

            <app-filters-find-home (close_apply)="applyFilters($event)" [remove]="{'roomType': true}" (close)="onFilterClose($event)" [ngStyle]="{'display': onFilters ? 'block' : 'none'}" class="menu-filters"></app-filters-find-home>
            </div>
          </section>

        <div class="container-left-right">

            <div class="left-side-properties">
                <app-property-sidebar 
                    [rentals]="(filtersToApply || (start.value && end.value)) ? rentals_filtered_by_search : rentals" 
                    (propertySelected)="filterByProperty($event)"
                    (groupTypeChanged)="activeGroupType = $event"
                    [groupType]="activeGroupType"></app-property-sidebar>
            </div>
            
            <div class="properties-container">
            
                @if(viewType === 'list'){
                     <div class="list-view">
                         <div class="listings-container">
                            @if(notFound) {
                              <div class="no-properties-message">
                                <mat-icon>search_off</mat-icon>
                                <h3>There are no properties</h3>
                                <p>No properties found for the selected filters</p>
                              </div>
                            } @else if(rentals_to_show.length) {
                              <app-all-listings [style2]="true" (delete)="deleteListing($event)" (toggleHide)="toggleHideListing($event)" [rentals]="rentals_to_show" [addSkeletonTop]="true"></app-all-listings>
                            } @else {
                              <app-all-listings [style2]="true" (delete)="deleteListing($event)" (toggleHide)="toggleHideListing($event)" [rentals]="rentals" [addSkeletonTop]="true"></app-all-listings>
                            }
                         </div>
                     </div>
                }@else if (viewType === 'calendar'){
                    <div class="calendar-view">
                        <div class="calendar-container">
                            @if(notFound) {
                              <div class="no-properties-message">
                                <mat-icon>search_off</mat-icon>
                                <h3>No properties available</h3>
                                <p>No properties found for the selected filters</p>
                              </div>
                            } @else {
                              <app-calendar-view 
                                  [rentals]="rentals_to_show.length ? rentals_to_show : rentals"
                                  [groupType]="activeGroupType"></app-calendar-view>
                            }
                        </div>
                    </div>
                }@else if(viewType === 'map'){
                    <div class="map-view">
                        <div class="map-container">
                            @if(notFound) {
                              <div class="no-properties-message">
                                <mat-icon>search_off</mat-icon>
                                <h3>No properties available</h3>
                                <p>No properties found for the selected filters</p>
                              </div>
                            } @else {
                              <div class="map-placeholder">
                                  <app-properties-map [rents]="rentals_to_show.length ? rentals_to_show : rentals"></app-properties-map>
                              </div>
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>
</section>
